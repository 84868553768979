@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tech-grid {
  background-image: 
    linear-gradient(to right, rgba(0, 241, 53, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 241, 53, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}

.hero-gradient {
  background: radial-gradient(circle at center, rgba(0, 241, 53, 0.15) 0%, transparent 70%);
}

.glass-panel {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}

@keyframes shine {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-shine {
  animation: shine 3s linear infinite;
}
