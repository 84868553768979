@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600&display=swap');

:root {
  /* Keep existing color scheme */
  --primary-color: #007BFF;
  --secondary-color: #708090;
  --accent-color: #32CD32;
  --background-color: #000000;
  --text-color: #FFFFFF;
  
  /* Add new design tokens */
  --glass-bg: rgba(255, 255, 255, 0.1);
  --glass-border: rgba(255, 255, 255, 0.2);
  --glass-blur: blur(10px);
  --card-shadow: 0 8px 32px rgba(0, 123, 255, 0.1);
  --transition-smooth: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Spacing system */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 2rem;
  --spacing-lg: 4rem;
  
  /* Border radius */
  --radius-sm: 8px;
  --radius-md: 16px;
  --radius-lg: 24px;
}

/* Base styles */
body {
  font-family: 'Roboto Mono', monospace;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

/* Glassmorphism utility classes */
.glass-panel {
  background: var(--glass-bg);
  backdrop-filter: var(--glass-blur);
  border: 1px solid var(--glass-border);
  border-radius: var(--radius-md);
}

/* Grid layouts */
.grid-container {
  display: grid;
  gap: var(--spacing-md);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: var(--spacing-md);
}

/* Animation keyframes */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes glow {
  0% { box-shadow: 0 0 5px var(--primary-color); }
  50% { box-shadow: 0 0 20px var(--primary-color); }
  100% { box-shadow: 0 0 5px var(--primary-color); }
}

@font-face {
  font-family: 'Horizon';
  src: url('horizon-webfont.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Add to your existing styles */
.hero-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 241, 53, 0.15) 0%,
    rgba(0, 241, 53, 0.12) 10%,
    rgba(0, 241, 53, 0.08) 20%,
    rgba(0, 241, 53, 0.05) 30%,
    rgba(0, 241, 53, 0.03) 45%,
    rgba(0, 241, 53, 0.01) 60%,
    transparent 80%
  );
  filter: blur(100px);
  mix-blend-mode: screen;
  opacity: 1;
  pointer-events: none;
}

.ambient-light {
  background: radial-gradient(
    circle at center,
    rgba(0, 241, 53, 0.1) 0%,
    transparent 60%
  );
  filter: blur(40px);
}

.tech-grid {
  background-image: 
    linear-gradient(to right, rgba(0,241,53,0.02) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0,241,53,0.02) 1px, transparent 1px);
  background-size: 30px 30px;
  mask-image: radial-gradient(circle at center, black 30%, transparent 70%);
  opacity: 0.7;
}

.ambient-glow {
  background: radial-gradient(
    circle at 50% 50%, 
    rgba(0,241,53,0.08) 0%, 
    rgba(0,123,255,0.05) 30%,
    transparent 70%
  );
  filter: blur(100px);
  opacity: 0.8;
}

.light-trails {
  background: linear-gradient(
    45deg,
    transparent 20%,
    rgba(0,241,53,0.02) 30%,
    rgba(0,123,255,0.03) 40%,
    rgba(0,241,53,0.02) 50%,
    transparent 80%
  );
  opacity: 0.7;
}

/* Add to your existing styles */
.scroll-section {
  position: relative;
  overflow: hidden;
}

.scroll-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, transparent, black);
  pointer-events: none;
}

/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 241, 53, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 241, 53, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 241, 53, 0.5);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shimmer {
  animation: shimmer 2s infinite;
}

/* Drone flight path effect */
.drone-path {
  position: absolute;
  width: 100%;
  height: 100%;
  background: 
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 100px,
      rgba(0, 241, 53, 0.03) 100px,
      rgba(0, 241, 53, 0.03) 200px
    );
  animation: dronePath 15s linear infinite;
  opacity: 0.5;
}

/* New keyframe animations */
@keyframes gridPulse {
  0%, 100% { opacity: 0.5; transform: scale(1); }
  50% { opacity: 0.8; transform: scale(1.05); }
}

@keyframes glowPulse {
  0%, 100% { opacity: 0.6; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.1); }
}

@keyframes rotateGradient {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes dronePath {
  0% { background-position: 0 0; }
  100% { background-position: 1000px 1000px; }
}

/* Enhanced shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.light-trails {
  background: linear-gradient(
    45deg,
    transparent 20%,
    rgba(0,241,53,0.05) 30%,
    rgba(0,123,255,0.08) 40%,
    rgba(0,241,53,0.05) 50%,
    transparent 80%
  );
  background-size: 200% 100%;
  animation: shimmer 10s linear infinite;
}

/* Section transitions */
.snap-y {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.snap-start {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
  z-index: 1;
}

/* Enhanced scrollbar for the snapping container */
.snap-y::-webkit-scrollbar {
  width: 8px;
}

.snap-y::-webkit-scrollbar-track {
  background: rgba(0, 241, 53, 0.05);
  border-radius: 4px;
}

.snap-y::-webkit-scrollbar-thumb {
  background: rgba(0, 241, 53, 0.2);
  border-radius: 4px;
  transition: background 0.3s ease;
}

.snap-y::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 241, 53, 0.3);
}

/* Section transition effect */
.snap-start {
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Add these mobile-specific styles */

/* Prevent text selection on touch */
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Allow text selection in specific elements */
input, textarea {
  -webkit-user-select: text;
  user-select: text;
}

/* Improved touch interactions */
@media (max-width: 640px) {
  .glass-panel {
    backdrop-filter: var(--glass-blur);
    -webkit-backdrop-filter: var(--glass-blur);
    touch-action: manipulation;
  }

  /* Optimize animations for mobile */
  .animate-shimmer {
    animation-duration: 1.5s;
  }

  /* Enhance touch feedback */
  .active\:scale-98:active {
    transform: scale(0.98);
  }

  /* Improve scrolling performance */
  .smooth-scroll {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Smooth content flow */
  .mobile-content-flow {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Enhanced mobile typography */
  .mobile-text-balance {
    text-wrap: balance;
    max-width: 42ch;
    margin: 0 auto;
  }

  /* Mobile card aesthetics */
  .mobile-card {
    background: rgba(0, 241, 53, 0.03);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 241, 53, 0.1);
    transition: all 0.3s ease;
  }

  .mobile-card:active {
    background: rgba(0, 241, 53, 0.05);
    transform: scale(0.98);
  }

  /* Mobile spacing utilities */
  .mobile-section-spacing > * + * {
    margin-top: 1.25rem;
  }

  .mobile-compact-layout {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 440px;
  }
}

/* Prevent overflow issues on mobile */
.overflow-fix {
  overflow-x: hidden;
  width: 100%;
}

/* Add these styles at the end of your file */

/* Navbar specific styles */
nav .glass-panel {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(0, 241, 53, 0.1);
  border-radius: var(--radius-lg);
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
}

nav .glass-panel:hover {
  border-color: rgba(0, 241, 53, 0.2);
  box-shadow: 0 0 20px rgba(0, 241, 53, 0.1);
}

/* Responsive navbar adjustments */
@media (max-width: 640px) {
  nav .glass-panel {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }
  
  nav .flex.items-center.gap-6 {
    gap: 0.75rem;
  }
}

/* Enhanced glass panel effect */
.glass-panel {
  border: 1px solid rgba(0, 241, 53, 0.1);
  border-radius: var(--radius-lg);
  transition: all 0.3s ease;
}

.glass-panel:hover {
  border-color: rgba(0, 241, 53, 0.2);
  box-shadow: 
    0 0 20px rgba(0, 241, 53, 0.1),
    inset 0 0 20px rgba(0, 241, 53, 0.05);
}

/* Improved button hover states */
.nav-button:hover {
  background: rgba(0, 241, 53, 0.1);
  transform: translateY(-1px);
}

/* Enhanced mobile styles */
@media (max-width: 640px) {
  .glass-panel {
    padding: 0.75rem 1rem;
    margin: 0.5rem;
  }
  
  .nav-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
}

/* Add this to your existing animations */
@keyframes gradientText {
  0% { color: #00F135; }
  25% { color: #40FF70; }
  50% { color: #00F135; }
  75% { color: #20FF55; }
  100% { color: #00F135; }
}

.animate-gradient-text {
  background: linear-gradient(to right, #00F135, #00F135/50);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Add this to your existing styles */
.font-jetbrains {
  font-family: 'JetBrains Mono', monospace;
}

/* Add pulse animation for hub cards */
@keyframes pulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

/* Add these mobile-specific styles */